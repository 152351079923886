import React, { FC, memo } from "react"
import {
  BannerStyled,
  ImgWrap,
  RootStyled,
  SubtitleStyled,
  TitleRedStyled,
  TitleStyled,
  WrapContent,
  WrapTextStyled,
} from "./styles"
import img1 from "./images/img4.png"
import img2 from "./images/img2.png"
import img3 from "./images/img3.png"

export const DigitalAssistants: FC = memo(() => {
  return (
    <RootStyled id="digital">
      <WrapContent>
        <WrapTextStyled>
          <TitleStyled>
            AI-GENERATED&nbsp;
            <TitleRedStyled>DIGITAL ASSISTANTS</TitleRedStyled>
          </TitleStyled>
          <SubtitleStyled>
            Create an omnichannel user experience to improve customer
            satisfaction.
          </SubtitleStyled>
          <SubtitleStyled>
            Increase your sales and engage your clients with Avatars as AI
            Assistants.
          </SubtitleStyled>
        </WrapTextStyled>
        <ImgWrap>
          <BannerStyled src={img1} />
          <BannerStyled src={img2} />
          <BannerStyled src={img3} />
        </ImgWrap>
      </WrapContent>
    </RootStyled>
  )
})
