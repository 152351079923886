import styled from "styled-components"

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const InputStyled = styled.input`
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  margin: 0;
  line-height: inherit;
  background: none;
  overflow: visible;
  width: 100%;
  border-radius: 4px;
  padding: 0 20px;
  height: 44px;
  background-color: rgba(246, 246, 246, 0.3);
  border: 2px solid #d8dde6;
  font-size: 14px;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  color: white;
  font-family: "Montserrat", Helvetica, sans-serif;

  :active {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    margin: 0;
    line-height: inherit;
    background: none;
    overflow: visible;
    width: 100%;
    border-radius: 4px;
    padding: 0 20px;
    height: 44px;
    background-color: rgba(246, 246, 246, 0.3);
    border: 2px solid #d8dde6;
    font-size: 14px;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    color: white;
    font-family: "Montserrat", Helvetica, sans-serif;
  }

  :hover {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    margin: 0;
    line-height: inherit;
    background: none;
    overflow: visible;
    width: 100%;
    border-radius: 4px;
    padding: 0 20px;
    height: 44px;
    background-color: rgba(246, 246, 246, 0.3);
    border: 2px solid #d8dde6;
    font-size: 14px;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    color: white;
    border-color: #a3afc4;
    font-family: "Montserrat", Helvetica, sans-serif;
  }

  :focus {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    margin: 0;
    line-height: inherit;
    background: none;
    overflow: visible;
    width: 100%;
    border-radius: 4px;
    padding: 0 20px;
    height: 44px;
    border: 2px solid #d8dde6;
    font-size: 14px;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    color: white;
    outline: none;
    background-color: rgba(246, 246, 246, 0.2);
    font-family: "Montserrat", Helvetica, sans-serif;
    border-color: #dff0ff;
  }

  :focus-within {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    margin: 0;
    line-height: inherit;
    background: none;
    overflow: visible;
    width: 100%;
    border-radius: 4px;
    padding: 0 20px;
    height: 44px;
    background-color: rgba(246, 246, 246, 0.3);
    border: 2px solid #d8dde6;
    font-size: 14px;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    color: white;
    font-family: "Montserrat", Helvetica, sans-serif;
  }

  :focus-visible {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    margin: 0;
    line-height: inherit;
    background: none;
    overflow: visible;
    width: 100%;
    border-radius: 4px;
    padding: 0 20px;
    height: 44px;
    background-color: rgba(246, 246, 246, 0.3);
    border: 2px solid #d8dde6;
    font-size: 14px;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    color: white;
    font-family: "Montserrat", Helvetica, sans-serif;
  }
`

export const LabelStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #86a2b6;
  margin-bottom: 6px;
`

export const InfoStyled = styled.span`
  color: #32de84;
  margin-top: 4px;
  font-size: 16px;
  height: 0px;
`
