import React, { FC, memo } from "react"
import {
  RootStyled,
  SubtitleStyled,
  TitleStyled,
  WrapTextStyled,
} from "./styles"
// import background from "./images/background.png"

export const WhoWeAre: FC = memo(() => {
  return (
    <RootStyled
      // style={{ backgroundImage: `url(${background})` }}
      id="who-we-are"
    >
      <WrapTextStyled>
        <TitleStyled>WHO WE ARE?</TitleStyled>
        <SubtitleStyled>
          NeuraLoom is a research and development team filled with PhDs,
          seasoned data scientists, and adept developers. Our mission is to
          craft exceptional software solutions for people, utilizing the most
          advanced AI and machine learning technologies available.
        </SubtitleStyled>
        <SubtitleStyled>
          Our primary goal is to ensure the AI Assistants we develop serve
          everyone, everywhere. We're deeply committed to leveraging
          cutting-edge technology to improve the quality of life for all
          humanity, aiming to solve real-world challenges and enhance daily
          living through innovation.
        </SubtitleStyled>
      </WrapTextStyled>
    </RootStyled>
  )
})
