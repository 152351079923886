import React, { FC, memo } from "react"
import { ImageStyled } from "../WhatWeOffer/styles"
import {
  RootStyled,
  WrapLeftStyled,
  WrapRightStyled,
  TitleStyled,
  TitleRedStyled,
  SubtitleStyled,
  WrapRightContentStyled,
} from "./styles"
import girl from "./images/girl.png"
import background from "./images/background.png"

export const Automation: FC = memo(() => {
  return (
    <RootStyled
      style={{ backgroundImage: `url(${background})` }}
      id="automation"
    >
      <WrapLeftStyled>
        <ImageStyled src={girl} />
      </WrapLeftStyled>
      <WrapRightStyled>
        <WrapRightContentStyled>
          <TitleStyled>EMBRACE EFFORTLESS EFFICIENCY!</TitleStyled>
          <TitleRedStyled>FULL AUTOMATION</TitleRedStyled>
          <SubtitleStyled>
            Utilize true-to-life AI Assistants on our platform or integrate them
            into your mobile and web applications.
          </SubtitleStyled>
        </WrapRightContentStyled>
      </WrapRightStyled>
    </RootStyled>
  )
})
