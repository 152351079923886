import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #1c3442;
  width: 100%;
  ${breakpoints.down("large")} {
    padding: 0 16px;
  }
`

export const TitleStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 49.6px;
  line-height: 55px;
  color: #ffffff;
  margin-bottom: 30px;
`
export const TitleRedStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 49.6px;
  line-height: 55px;
  color: #ee0081;
  margin-bottom: 30px;
`
export const SubtitleStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 38px;
  color: #95b3c5;
  opacity: 0.9;
`

export const WrapTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 64px;
  margin-top: 135px;
`

export const IconsWrap = styled.div`
  display: flex;
  gap: 60px;
  justify-content: center;
  align-items: center;
  margin-bottom: 125px;
  flex-wrap: wrap;
`

export const LogoImgStyled = styled.img`
  --dark: #000000;
  --light: #e8e8e8;
  --primary: #0085ff;
  --primary-dark: #0077e4;
  --accent: #dff0ff;
  --secondary: #0085ff;
  --secondary-dark: #0077e4;
  -webkit-font-smoothing: antialiased;
  color: #3d3d3d;
  font-size: 16px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  list-style: none;
  box-sizing: border-box;
  transform: translateZ(0);
  filter: grayscale(1);
  transition: filter 0.15s ease, -webkit-filter 0.15s ease;
  font-family: "Montserrat", Helvetica, sans-serif;

  :hover {
    --dark: #000000;
    --light: #e8e8e8;
    --primary: #0085ff;
    --primary-dark: #0077e4;
    --accent: #dff0ff;
    --secondary: #0085ff;
    --secondary-dark: #0077e4;
    -webkit-font-smoothing: antialiased;
    color: #3d3d3d;
    font-size: 16px;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transform: translateZ(0);
    transition: filter 0.15s ease, -webkit-filter 0.15s ease;
    filter: grayscale(0);
    font-family: "Montserrat", Helvetica, sans-serif;
  }
`
