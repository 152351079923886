import React, { FC, memo } from "react"
import arrow from "../../../Icons/arrow.svg"
import background from "./images/background.png"
import {
  RootStyled,
  TitleStyled,
  // HeaderStyled,
  TitleRedStyled,
  SubtitleStyled,
  WrapTextStyled,
  WrapColumnsStyled,
  ColumnStyled,
  HeaderStyled,
  ItemLabelStyled,
  WrapItemsStyled,
  LogoImgStyled,
  WrapItemStyled,
} from "./styles"

export const Explore: FC = memo(() => {
  const renderColumn = (header: string, items: string[]) => {
    return (
      <ColumnStyled>
        <HeaderStyled>{header}</HeaderStyled>
        <WrapItemsStyled>
          {items.map((item) => (
            <WrapItemStyled key={item}>
              <LogoImgStyled src={arrow} height="43" />
              <ItemLabelStyled>{item}</ItemLabelStyled>
            </WrapItemStyled>
          ))}
        </WrapItemsStyled>
      </ColumnStyled>
    )
  }

  return (
    <RootStyled style={{ backgroundImage: `url(${background})` }} id="explore">
      <WrapTextStyled>
        <TitleStyled>SOON, THERE'LL BE SO MUCH TO</TitleStyled>
        <TitleRedStyled>EXPLORE</TitleRedStyled>
        <SubtitleStyled>
          Engage your customers in communication channels with true-to-life
          digital avatars
        </SubtitleStyled>
      </WrapTextStyled>
      <WrapColumnsStyled>
        {renderColumn("COMMUNICATION", [
          "Omnichannels",
          "Real-time render",
          "Synthetic media production",
          "Web-based and stand alone apps",
        ])}
        {renderColumn("CUSTOMIZATION", [
          "Custom avatars",
          "Custom voices",
          "Style voice transfer",
          "Custom knowledge base for LLM's",
          "100+ languages",
        ])}
        {renderColumn("AUTOMATION", [
          "Control via API",
          "SDK",
          "Text to motion",
        ])}
      </WrapColumnsStyled>
    </RootStyled>
  )
})
