import styled from "styled-components"
import { breakpoints } from "media"

export const ButtonStyled = styled.button<{ disabled?: boolean }>`
  background: #3a486c;
  height: 44px;
  width: max-content;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  padding: 0;
  :hover {
    transform: ${(props) => (props.disabled ? "" : "translateY(-2px)")};
  }

  ${breakpoints.down("medium")} {
    width: 100%;
  }
`

export const LabelStyled = styled.label<{ disabled?: boolean }>`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* identical to box height, or 180% */
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 7px 32px;
  display: flex;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: inline;
`
