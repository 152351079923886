import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #14193e;
`

export const TitleStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 44.8px;
  line-height: 49px;
  color: #95b3c5;
  margin-top: 120px;
  text-align: center;
  margin-bottom: 54px;
  ${breakpoints.down("large")} {
    margin-top: 100px;
  }
`
export const TitleRedStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 44.8px;
  line-height: 49px;
  color: #ee0081ee;
`

export const MaxWidthEmail = styled.div`
  width: 100%;
  max-width: 500px;
  align-self: center;
  margin-bottom: 160px;
  ${breakpoints.down("large")} {
    padding: 0 16px;
  }
`
