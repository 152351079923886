import React, { FC, memo } from "react"
import {
  RootStyled,
  WrapLeftStyled,
  WrapRightStyled,
  GirlStyled,
  TitleStyled,
  TitleRedStyled,
  SubtitleStyled,
  WrapRightContentStyled,
} from "./styles"
import girl from "./images/girl.png"
import background from "./images/background.png"

export const Support: FC = memo(() => {
  return (
    <RootStyled
      style={{ backgroundImage: `url(${background})` }}
      id="assistant"
    >
      <WrapRightStyled>
        <WrapRightContentStyled>
          <TitleStyled>PERSONALIZE</TitleStyled>
          <TitleRedStyled>YOUR ASSISTANT</TitleRedStyled>
          <SubtitleStyled>
            Your Assistant will appear as you desire, speak in a voice you
            enjoy, and be tailored to the specifics of your business and the
            challenges you aim to solve.
          </SubtitleStyled>
        </WrapRightContentStyled>
      </WrapRightStyled>
      <WrapLeftStyled>
        <GirlStyled src={girl} />
      </WrapLeftStyled>
    </RootStyled>
  )
})
