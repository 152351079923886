import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  background: #050e2e;
  padding-top: 70px;
  justify-content: space-between;
  flex-direction: row;
  ${breakpoints.down("large")} {
    flex-direction: column;
    gap: 50px;
    padding: 0 16px;
  }
`

export const Label22Styled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  opacity: 0.6;
`

export const WrapLabel22Styled = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`

export const Label24Styled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
`

export const WrapLabel24Styled = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`

export const WrapLeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  min-width: 200px;
  margin-bottom: 70px;
  width: 20%;
  ${breakpoints.down("large")} {
    margin: 70px 0 50px 0;
    width: 100%;
  }
`

export const LogoStyled = styled.a`
  transition: opacity 0.25s ease;
  :hover {
    color: inherit;
    opacity: 0.7;
  }
`

export const LogoImgStyled = styled.img`
  height: 21px;
`

export const WrapColumn2Styled = styled.div`
  width: 20%;
  min-width: 200px;
  margin-bottom: 70px;
  gap: 22px;
  flex-direction: column;
  display: flex;
  ${breakpoints.down("large")} {
    margin-bottom: 0;
  }
`
export const WrapColumn3Styled = styled.div`
  width: 20%;
  min-width: 200px;
  margin-bottom: 70px;
  gap: 22px;
  flex-direction: column;
  display: flex;
  ${breakpoints.down("large")} {
    margin-bottom: 0;
  }
`
export const WrapColumn4Styled = styled.div`
  width: 20%;
  min-width: 200px;
  margin-bottom: 70px;
  display: flex;
  gap: 12px;
  ${breakpoints.down("large")} {
    margin-bottom: 50px;
  }
`

export const LinkStyled = styled.a`
  text-decoration: none;
  transition: opacity 0.2s ease;

  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  ${breakpoints.down("large")} {
    font-size: 16px;
  }

  :hover {
    opacity: 0.7;
  }
`

export const XAStyled = styled.a`
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: opacity 0.2s ease, transform 0.2s ease;
  font-family: "Montserrat", Helvetica, sans-serif;
  filter: brightness(0) invert(1);

  :hover {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-family: "Montserrat", Helvetica, sans-serif;
    color: inherit;
    opacity: 0.8;
    transform: translateY(-2px);
    filter: brightness(0) invert(1);
  }
`

export const YoutubeAStyled = styled.a`
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  font-family: "Montserrat", Helvetica, sans-serif;
  color: inherit;
  filter: brightness(0) invert(1);

  :hover {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-family: "Montserrat", Helvetica, sans-serif;
    color: inherit;
    opacity: 0.8;
    transform: translateY(-2px);
    filter: brightness(0) invert(1);
  }
`

export const InAStyled = styled.a`
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  font-family: "Montserrat", Helvetica, sans-serif;
  color: inherit;
  filter: brightness(0) invert(1);

  :hover {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-family: "Montserrat", Helvetica, sans-serif;
    color: inherit;
    opacity: 0.8;
    transform: translateY(-2px);
    filter: brightness(0) invert(1);
  }
`

export const FBAStyled = styled.a`
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  font-family: "Montserrat", Helvetica, sans-serif;
  color: inherit;
  filter: brightness(0) invert(1);

  :hover {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-family: "Montserrat", Helvetica, sans-serif;
    color: inherit;
    opacity: 0.8;
    transform: translateY(-2px);
    filter: brightness(0) invert(1);
  }
`

export const MAStyled = styled.a`
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  font-family: "Montserrat", Helvetica, sans-serif;
  color: inherit;
  filter: brightness(0) invert(1);
  width: 25%;

  :hover {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-family: "Montserrat", Helvetica, sans-serif;
    color: inherit;
    opacity: 0.8;
    transform: translateY(-2px);
    filter: brightness(0) invert(1);
  }
`
