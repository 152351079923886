import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  ${breakpoints.down("large")} {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 60px;
  }
`
export const WrapLeftStyled = styled.div`
  width: 50%;
  text-align: center;
  ${breakpoints.down("large")} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const WrapRightStyled = styled.div`
  width: 50%;
  align-self: center;
  ${breakpoints.down("large")} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
  }
`

export const WrapRightContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 540px;
`

export const TitleStyled = styled.h2`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 44.8px;
  line-height: 49px;
  color: #95b3c5;
  ${breakpoints.down("large")} {
    text-align: center;
  }
`
export const TitleRedStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 44.8px;
  line-height: 49px;
  color: #ee0081;
  margin-bottom: 16px;
  ${breakpoints.down("large")} {
    text-align: center;
  }
`
export const SubtitleStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  opacity: 0.9;
  ${breakpoints.down("large")} {
    text-align: center;
  }
`
