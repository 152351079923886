import React, { FC, memo } from "react"
import {
  RootStyled,
  WrapLeftStyled,
  WrapRightStyled,
  GirlStyled,
  TitleStyled,
  TitleRedStyled,
  SubtitleStyled,
  WrapRightContentStyled,
} from "./styles"
import girl from "./images/girl.png"
import background from "./images/background.png"

export const AroundTheClock: FC = memo(() => {
  return (
    <RootStyled style={{ backgroundImage: `url(${background})` }} id="support">
      <WrapLeftStyled>
        <GirlStyled src={girl} />
      </WrapLeftStyled>
      <WrapRightStyled>
        <WrapRightContentStyled>
          <TitleStyled>AROUND-THE-CLOCK ASSISTANCE!</TitleStyled>
          <TitleRedStyled>24/7 SUPPORT</TitleRedStyled>
          <SubtitleStyled>
            Our AI Assistants work round-the-clock to assist you and your
            customers.
          </SubtitleStyled>
        </WrapRightContentStyled>
      </WrapRightStyled>
    </RootStyled>
  )
})
