import React, { FC, memo } from "react"
import { RootStyled } from "./styles"
import { WeWillBeBackSoon } from "./components/WeWillBeBackSoon"
import { WhatWeOffer } from "./components/WhatWeOffer"
import { EatYourOwnCooking } from "./components/EatYourOwnCooking"
import { Automation } from "./components/Automation"
import { EasyStep } from "./components/EasyStep"
import { Support } from "./components/Support"
import { AroundTheClock } from "./components/AroundTheClock"
import { WhoWeAre } from "./components/WhoWeAre"
import { DigitalAssistants } from "./components/DigitalAssistants"
import { Partners } from "./components/Partners"
import { Explore } from "./components/Explore"
import { WantToBeInformed } from "./components/WantToBeInformed"
import { Footer } from "./components/Footer"

export const Main: FC = memo(() => {
  return (
    <RootStyled>
      <WeWillBeBackSoon />
      <WhatWeOffer />
      <EatYourOwnCooking />
      <Automation />
      <EasyStep />
      <AroundTheClock />
      <Support />
      <WhoWeAre />
      <DigitalAssistants />
      <Partners />
      <Explore />
      <WantToBeInformed />
      <Footer />
    </RootStyled>
  )
})
