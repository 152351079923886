import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  background: #14193e;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
`

export const TitleStyled = styled.h2`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 85.3px;
  line-height: 179px;
  display: flex;
  align-items: center;
  color: #ee0081;
  ${breakpoints.down("large")} {
    font-size: 41px;
    line-height: 1.2;
  }
`

export const SubtitleStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  align-items: center;
  color: #ffffff;
`

export const WrapTextStyled = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 82px;
  margin-bottom: 100px;
  ${breakpoints.down("large")} {
    padding: 0 16px;
  }
`
