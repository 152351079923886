import React from "react"
// import logo from './logo.svg';
// import './App.css';
import { Main } from "./components/Main"
import "index.css"

function App() {
  return <Main />
}

export default App
