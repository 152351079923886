import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #14193e;
`

export const LogoStyled = styled.a`
  width: fit-content;
  transition: opacity 0.25s ease;
  :hover {
    color: inherit;
    opacity: 0.7;
  }
`

export const LogoImgStyled = styled.img`
  margin-left: 32px;
  margin-top: 30px;
`

export const WrapContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  ${breakpoints.down("large")} {
    flex-direction: column;
    align-items: center;
  }
  ${breakpoints.down("medium")} {
    padding: 0 16px;
  }
`
export const WrapContentLeftStyled = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  ${breakpoints.down("large")} {
    width: 100%;
    margin: 120px 0 40px 0;
  }
`

export const ContentLeftStyled = styled.div`
  width: 100%;
  max-width: 490px;
  align-self: center;
  margin: auto 0px;
`

export const WrapContentRightStyled = styled.div`
  width: 50%;
  overflow: hidden;
  ${breakpoints.down("large")} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const GirlStyled = styled.img`
  max-height: 691px;
  height: auto;
  position: relative;
  bottom: -10px;
  ${breakpoints.down("large")} {
    max-height: 500px;
    bottom: -4px;
  }
  ${breakpoints.down("medium")} {
    max-height: 360px;
    bottom: -2px;
  }
`

export const TitleStyled = styled.h1`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 57.6px;
  line-height: 63px;
  align-items: center;
  color: #ffffff;
  display: flex;
  margin-bottom: 42px;

  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 57.6px;
  line-height: 63px;
  /* identical to box height, or 110% */
  display: flex;
  align-items: center;

  color: #ffffff;

  ${breakpoints.down("large")} {
    text-align: center;
  }
`

export const SubtitleStyled = styled.span`
  font-family: "Alliance No.1", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  display: flex;
  margin-bottom: 49px;
  ${breakpoints.down("large")} {
    text-align: center;
  }
`

export const WrapEmail = styled.div`
  display: flex;
  gap: 16px;
  ${breakpoints.down("large")} {
    flex-wrap: wrap;
    gap: 0;
  }
`

export const WrapButton = styled.div`
  margin-top: 21px;
  ${breakpoints.down("large")} {
    margin-top: 30px;
    width: 100%;
  }
`
