import React, { FC, memo } from "react"
import {
  RootStyled,
  TitleStyled,
  TitleRedStyled,
  SubtitleStyled,
  WrapTextStyled,
  IconsWrap,
  LogoImgStyled,
} from "./styles"
import amazon from "./icons/amazon-logo.svg"
import ibm from "./icons/ibm.svg"
import google from "./icons/google.svg"
import injib from "./icons/logo-injib.svg"
import nvidia from "./icons/nvidia-logo.svg"
import microsoft from "./icons/microsoft.svg"

export const Partners: FC = memo(() => {
  return (
    <RootStyled id="partners">
      <WrapTextStyled>
        <TitleRedStyled>
          PARTNERS&nbsp;
          <TitleStyled>AND SUPPORTERS</TitleStyled>
        </TitleRedStyled>
        <SubtitleStyled>
          Together, we're transforming visions into reality
        </SubtitleStyled>
      </WrapTextStyled>
      <IconsWrap>
        <LogoImgStyled height="43" src={microsoft} />
        <LogoImgStyled src={google} height="43" />
        <LogoImgStyled src={nvidia} height="43" />
        <LogoImgStyled src={amazon} height="43" />
        <LogoImgStyled src={ibm} height="43" />
        <LogoImgStyled src={injib} height="89" />
      </IconsWrap>
    </RootStyled>
  )
})
