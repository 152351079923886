import React, { FC, memo } from "react"
import logo from "../../../Icons/logo.svg"
import {
  RootStyled,
  WrapLeftStyled,
  Label24Styled,
  Label22Styled,
  LogoStyled,
  LogoImgStyled,
  WrapColumn4Styled,
  WrapColumn2Styled,
  WrapColumn3Styled,
  LinkStyled,
  XAStyled,
  YoutubeAStyled,
  InAStyled,
  FBAStyled,
  MAStyled,
  WrapLabel22Styled,
  WrapLabel24Styled,
} from "./styles"

export const Footer: FC = memo(() => {
  return (
    <RootStyled>
      <WrapLeftStyled>
        <LogoStyled href="/" target="_self">
          <LogoImgStyled alt="NeuraLoom full" src={logo} />
        </LogoStyled>
        <WrapLabel22Styled>
          <Label22Styled>© 2019-2024 NeuraLoom Inc.</Label22Styled>
          <Label22Styled>All rights reserved</Label22Styled>
        </WrapLabel22Styled>
        <WrapLabel24Styled>
          <Label24Styled>838 6th Avenue, 6th Floor</Label24Styled>
          <Label24Styled>New York, NY 10001</Label24Styled>
        </WrapLabel24Styled>
      </WrapLeftStyled>
      <WrapColumn2Styled>
        <LinkStyled href="#home">Home</LinkStyled>
        <LinkStyled href="#offer">What we offer?</LinkStyled>
        <LinkStyled href="#automation">Automation</LinkStyled>
        <LinkStyled href="#easy-setup">Easy setup</LinkStyled>
        <LinkStyled href="#support">24/7 support</LinkStyled>
        <LinkStyled href="#assistant">Personalize your assistant</LinkStyled>
        <LinkStyled href="#who-we-are">Who we are?</LinkStyled>
        <LinkStyled href="#digital">Digital assistants</LinkStyled>
        <LinkStyled href="#partners">Partners and supporters</LinkStyled>
        <LinkStyled href="#explore">Explore</LinkStyled>
      </WrapColumn2Styled>
      <WrapColumn3Styled>
        <LinkStyled href="#">Terms of Use</LinkStyled>
        <LinkStyled href="#">Ethics Guidelines</LinkStyled>
        <LinkStyled href="#">Privacy Policy</LinkStyled>
        <LinkStyled href="#">KYC Policy</LinkStyled>
        <LinkStyled href="#">Cookies Notice</LinkStyled>
      </WrapColumn3Styled>
      <WrapColumn4Styled>
        <XAStyled
          href="https://twitter.com/neuraloom"
          target="_blank"
          rel="noreferrer"
        >
          <img
            loading="lazy"
            alt="twitter icon"
            src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/color/x.svg"
            width={18}
          />
        </XAStyled>
        <YoutubeAStyled
          href="https://www.youtube.com/@neuraloom"
          target="_blank"
          rel="noreferrer"
        >
          <img
            loading="lazy"
            alt="youtube icon"
            src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/color/youtube.svg"
            width={18}
          />
        </YoutubeAStyled>
        <InAStyled
          href="https://www.linkedin.com/company/neuraloom/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            loading="lazy"
            alt="linkedin icon"
            src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/color/linkedin.svg"
            width={18}
          />
        </InAStyled>
        <FBAStyled
          href="https://www.facebook.com/neuraloom"
          target="_blank"
          rel="noreferrer"
        >
          <img
            loading="lazy"
            alt="facebook icon"
            src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/color/facebook.svg"
            width={18}
          />
        </FBAStyled>
        <MAStyled
          className="social-buttons__link social-buttons__link--medium"
          href="https://neuraloom.medium.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            loading="lazy"
            className="social-buttons__icon"
            alt="medium icon"
            src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/color/medium.svg"
            width={18}
          />
        </MAStyled>
      </WrapColumn4Styled>
    </RootStyled>
  )
})
