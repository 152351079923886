import React, { FC, memo, useState } from "react"
import logo from "../../../Icons/logo.svg"
import { Input } from "../../../Input"
import { Button } from "../../../Button"
import { sendEmail } from "../../../../axios"
import girl from "./images/girl.png"
import {
  LogoImgStyled,
  LogoStyled,
  RootStyled,
  WrapContentStyled,
  WrapContentLeftStyled,
  WrapContentRightStyled,
  GirlStyled,
  TitleStyled,
  SubtitleStyled,
  ContentLeftStyled,
  WrapEmail,
  WrapButton,
} from "./styles"

export const WeWillBeBackSoon: FC = memo(() => {
  const [email, setEmail] = useState("")
  const [info, setInfo] = useState("")
  const onClick = () => {
    sendEmail(email)
      .then(() => {
        setEmail("")
        setInfo("Success")
        setTimeout(() => {
          setInfo("")
        }, 3000)
      })
      .catch(() => {})
  }

  const onChange = (e: any) => {
    setEmail(e?.target?.value)
  }

  const validateEmail = (value: string) => {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const getDisabled = () => {
    return !validateEmail(email)
  }

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (validateEmail(email)) {
        onClick()
      }
    }
  }

  return (
    <RootStyled id="home">
      <LogoStyled href="/" target="_self">
        <LogoImgStyled height="37" alt="NeuraLoom full" src={logo} />
      </LogoStyled>
      <WrapContentStyled>
        <WrapContentLeftStyled>
          <ContentLeftStyled>
            <TitleStyled>WE'LL BE BACK SOON</TitleStyled>
            <SubtitleStyled>
              Our site is undergoing maintenance for an extended period today.
              Thanks for your patience.
            </SubtitleStyled>
            <WrapEmail>
              <Input
                label="Enter your email:"
                inputProps={{
                  type: "email",
                  name: "EMAIL",
                  required: true,
                  placeholder: "your@email.com",
                  onChange,
                  value: email,
                  onKeyDown,
                }}
                info={info}
              />
              <WrapButton>
                <Button
                  label="BE INFORMED"
                  onClick={onClick}
                  disabled={getDisabled()}
                />
              </WrapButton>
            </WrapEmail>
          </ContentLeftStyled>
        </WrapContentLeftStyled>
        <WrapContentRightStyled>
          <GirlStyled src={girl} />
        </WrapContentRightStyled>
      </WrapContentStyled>
    </RootStyled>
  )
})
