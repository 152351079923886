import React, { FC, memo } from "react"
import {
  RootStyled,
  TitleStyled,
  SubtitleStyled,
  WrapLeftStyled,
  WrapRightStyled,
  WrapRightContentStyled,
  TitleRedStyled,
  ImageStyled,
} from "./styles"
import background from "./images/background.jpg"
import speaker from "./images/speaker.png"

export const WhatWeOffer: FC = memo(() => {
  return (
    <RootStyled
      style={{ backgroundImage: `url(${background})` }}
      id="automation"
    >
      <WrapLeftStyled>
        <ImageStyled src={speaker} />
      </WrapLeftStyled>
      <WrapRightStyled>
        <WrapRightContentStyled>
          <TitleStyled>
            IN THE MEANTIME... FOCUS ON THE IMPORTANT THINGS.
          </TitleStyled>
          <TitleRedStyled>FULL AUTOMATION</TitleRedStyled>
          <SubtitleStyled>
            Avatars as AI Assistants that resolve real-life challenges and
            create emotional connections with you or your potential clients.
          </SubtitleStyled>
        </WrapRightContentStyled>
      </WrapRightStyled>
    </RootStyled>
  )

  // return (
  //   <RootStyled imgUrl={background} id="offer">
  //     <LeftStyled>
  //       <WrapLeftStyled>
  //         <TitleStyled>
  //           IN THE MEANTIME... FOCUS ON THE IMPORTANT THINGS.
  //         </TitleStyled>
  //         <RedLabelStyled>WHAT WE OFFER?</RedLabelStyled>
  //         <SubtitleStyled>
  //           Avatars as AI Assistants that resolve real-life challenges and
  //           create emotional connections with you or your potential clients.
  //         </SubtitleStyled>
  //       </WrapLeftStyled>
  //     </LeftStyled>
  //     <RightStyled>
  //       <ImageStyled src={speaker} />
  //     </RightStyled>
  //   </RootStyled>
  // )
})
