import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-top: 125px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  ${breakpoints.down("large")} {
    padding: 0 16px;
    padding-top: 125px;
  }
`

export const WrapTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const TitleStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 51.2px;
  line-height: 62px;
  color: #ffffff;
`
export const TitleRedStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 51.2px;
  line-height: 62px;
  color: #ee0081;
  margin-bottom: 28px;
`
export const SubtitleStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 38px;
  color: #95b3c5;
  opacity: 0.9;
  margin-bottom: 50px;
`
export const HeaderStyled = styled.span`
  display: flex;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 19px;
`

export const WrapColumnsStyled = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  justify-content: center;
  gap: 120px;
  align-self: center;
  margin-bottom: 160px;
  ${breakpoints.down("large")} {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
  }
`
export const ColumnStyled = styled.div`
  width: 100%;
`

export const ItemLabelStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 25px;
  color: #ffffff;
`

export const WrapItemsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const WrapItemStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const LogoImgStyled = styled.img``
