import { breakpoints } from "media"
import styled from "styled-components"

export const RootStyled = styled.div`
  background: #1c3d50;
  display: flex;
  flex-direction: column;
`

export const TitleStyled = styled.h2`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 98px;
  line-height: 55px;
  color: #95b3c5;
  margin-bottom: 60px;

  ${breakpoints.down("huge")} {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 40px;
  }
  ${breakpoints.down("large")} {
    font-size: 40px;
    line-height: 1.2;
    align-self: center;
  }
  ${breakpoints.down("medium")} {
    font-size: 40px;
    line-height: 1.2;
    align-self: center;
  }
  ${breakpoints.down("small")} {
    font-size: 40px;
    line-height: 1.2;
    align-self: center;
  }
`
export const TitleRedStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 98px;
  line-height: 55px;
  color: #ee0081;

  ${breakpoints.down("huge")} {
    font-size: 40px;
    line-height: 1.2;
  }
  ${breakpoints.down("large")} {
    font-size: 40px;
    line-height: 1.2;
  }
  ${breakpoints.down("medium")} {
    font-size: 40px;
    line-height: 1.2;
  }
  ${breakpoints.down("small")} {
    font-size: 40px;
    line-height: 1.2;
  }
`
export const SubtitleStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  opacity: 0.9;
  margin-left: 138px;

  ${breakpoints.down("huge")} {
    font-size: 22px;
    line-height: 1.6;
    margin-left: 0px;
  }
  ${breakpoints.down("large")} {
    font-size: 22px;
    line-height: 1.6;
    margin-left: 0px;
    align-self: center;
  }
  ${breakpoints.down("medium")} {
    font-size: 22px;
    line-height: 1.6;
    margin-left: 0px;
  }
  ${breakpoints.down("small")} {
    font-size: 22px;
    line-height: 1.6;
    margin-left: 0px;
  }
`

export const WrapTextStyled = styled.div`
  width: 100%;
  padding-top: 180px;
  padding-bottom: 121px;
  display: flex;
  flex-direction: column;
  align-self: center;
  ${breakpoints.down("huge")} {
    padding-bottom: 80px;
    padding-top: 64px;
  }
  ${breakpoints.down("large")} {
    padding: 64px 16px 50px 16px;
  }
`

export const BannerStyled = styled.img`
  width: 430px;

  ${breakpoints.down("huge")} {
    width: 320px;
  }

  ${breakpoints.down("large")} {
    width: 300px;
  }
  ${breakpoints.down("medium")} {
    padding: 0px 16px;
    width: 100%;
  }
`

export const ImgWrap = styled.div`
  display: flex;
  gap: 59px;
  //align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 221px;
  ${breakpoints.down("large")} {
    margin-bottom: 120px;
  }
`

export const WrapContent = styled.div`
  align-self: center;
`
