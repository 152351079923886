import React, { FC, memo } from "react"
import { ButtonStyled, LabelStyled } from "./styles"

interface IProps {
  label: string
  onClick(): void
  disabled?: boolean
}

export const Button: FC<IProps> = memo(({ label, onClick, disabled }) => {
  return (
    <ButtonStyled onClick={onClick} disabled={disabled}>
      <LabelStyled disabled={disabled}>{label}</LabelStyled>
    </ButtonStyled>
  )
})
