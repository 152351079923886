import React, { FC, memo } from "react"
import {
  BannerStyled,
  RedTitleStyled,
  RootStyled,
  SubtitleStyled,
  TitleStyled,
  WrapTextStyled,
} from "./styles"
import banner from "./images/banner.png"

export const EatYourOwnCooking: FC = memo(() => {
  return (
    <RootStyled id="">
      <WrapTextStyled>
        <RedTitleStyled>
          EAT YOUR OWN COOKING:&nbsp;
          <TitleStyled>
            WE CAN'T IMAGINE OUR LIFE WITHOUT AI ASSISTANTS
          </TitleStyled>
        </RedTitleStyled>
        <SubtitleStyled>
          We're not just creators and developers! We're avid users of our AI
          Assistants, fully integrating them into our daily routines. This isn't
          just about technology for us, it's about reshaping how we work,
          communicate, and grow.
        </SubtitleStyled>
        <SubtitleStyled>
          Personalized customer outreach, real-time analytics, translators,
          copywriters, sounding board, and many more... AI Assistants aren't
          just a part of our product lineup. They're embedded in the fabric of
          our life, demonstrating every day how indispensable they've become.
        </SubtitleStyled>
      </WrapTextStyled>
      <BannerStyled src={banner} />
    </RootStyled>
  )
})
