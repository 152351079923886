import React, { FC, memo } from "react"
import {
  RootStyled,
  WrapLeftStyled,
  WrapRightStyled,
  LecturerStyled,
  TitleStyled,
  TitleRedStyled,
  SubtitleStyled,
  WrapRightContentStyled,
} from "./styles"
import lecturer from "./images/lecturer.png"
import background from "./images/background.png"

export const EasyStep: FC = memo(() => {
  return (
    <RootStyled
      style={{ backgroundImage: `url(${background})` }}
      id="easy-setup"
    >
      <WrapLeftStyled>
        <WrapRightContentStyled>
          <TitleStyled>STREAMLINE YOUR START!</TitleStyled>
          <TitleRedStyled>EASY SETUP</TitleRedStyled>
          <SubtitleStyled>
            Get started quickly with simple tools. No advanced tech skills
            required.
          </SubtitleStyled>
        </WrapRightContentStyled>
      </WrapLeftStyled>
      <WrapRightStyled>
        <LecturerStyled src={lecturer} />
      </WrapRightStyled>
    </RootStyled>
  )
})
