import styled from "styled-components"
import { breakpoints } from "media"

export const RootStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #1c3d50;
`

export const WrapTextStyled = styled.div`
  width: 100%;
  max-width: 100%;
  align-self: center;
  margin: 48px auto;
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 0 16px;
  ${breakpoints.up("large")} {
    max-width: 870px;
  }
`

export const TitleStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 35.2px;
  line-height: 39px;
  color: #95b3c5;
`
export const RedTitleStyled = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 35.2px;
  line-height: 39px;
  color: #ee0081;
`

export const SubtitleStyled = styled.span`
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  opacity: 0.9;
`

export const BannerStyled = styled.img`
  width: 100%;
  margin-bottom: 54px;
`
