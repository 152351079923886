import React, { FC, memo, useState } from "react"
import { Input } from "../../../Input"
import { WrapButton, WrapEmail } from "../WeWillBeBackSoon/styles"
import { Button } from "../../../Button"
import { sendEmail } from "../../../../axios"
import background from "./images/background.png"
import {
  RootStyled,
  TitleStyled,
  TitleRedStyled,
  MaxWidthEmail,
} from "./styles"

export const WantToBeInformed: FC = memo(() => {
  const [email, setEmail] = useState("")
  const [info, setInfo] = useState("")
  const onClick = () => {
    sendEmail(email)
      .then(() => {
        setEmail("")
        setInfo("Success")
        setTimeout(() => {
          setInfo("")
        }, 3000)
      })
      .catch(() => {})
  }

  const onChange = (e: any) => {
    setEmail(e?.target?.value)
  }

  const validateEmail = (value: string) => {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const getDisabled = () => {
    return !validateEmail(email)
  }

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (validateEmail(email)) {
        onClick()
      }
    }
  }

  return (
    <RootStyled style={{ backgroundImage: `url(${background})` }} id="info">
      <TitleStyled>
        WANT TO&nbsp;
        <TitleRedStyled>BE INFORMED</TitleRedStyled>?
      </TitleStyled>
      <MaxWidthEmail>
        <WrapEmail>
          <Input
            label="Enter your email:"
            info={info}
            inputProps={{
              type: "email",
              name: "EMAIL",
              required: true,
              placeholder: "your@email.com",
              onChange,
              value: email,
              onKeyDown,
            }}
          />
          <WrapButton>
            <Button
              label="SUBSCRIBE"
              onClick={onClick}
              disabled={getDisabled()}
            />
          </WrapButton>
        </WrapEmail>
      </MaxWidthEmail>
    </RootStyled>
  )
})
