import React, { FC, memo } from "react"
import { InfoStyled, InputStyled, LabelStyled, RootStyled } from "./styles"

interface IProps {
  label: string
  inputProps?: any
  info?: string
}

export const Input: FC<IProps> = memo(({ label, inputProps, info }) => {
  return (
    <RootStyled>
      <LabelStyled>{label}</LabelStyled>
      <InputStyled {...inputProps} />
      <InfoStyled>{info}</InfoStyled>
    </RootStyled>
  )
})
